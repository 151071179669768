import Slider from '@/components/molecules/Slider';
import { ActionBlockData } from '@/services/types/blocksTypes';
import { getImage, hexToRGB } from '@/services/utils/helper';
import cls from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styles from './styles.module.scss';

interface ActionsProps {
	data: ActionBlockData[];
	label: string;
	blockLinks?: any;
}
const ActionSideScrollUnderline: React.FC<ActionsProps> = ({ data, label, blockLinks }) => {
	const { children } = blockLinks;
	return (
		<div className={cls('pb-60 ', styles.action_side_scroll_underline)}>
			<div className='container product-slider'>
				<div className='text-center ' style={{ textAlign: 'center', marginBottom: 30 }}>
					<h2 className='text-gray900 text-30 fw-600'>{label}</h2>
				</div>
				<Slider slidesPerView={5.5} spaceBetween={20}
				shouldSlidesPerGroup={true}
				responsive={{
					'0': {
						slidesPerView: 2
					},
					'640': {
						slidesPerView: 2
					},
					'768': {
						slidesPerView: 2
					},
					'800': {
						slidesPerView: 2.5,
						spaceBetween: 10
					},
					'1024': {
						slidesPerView: 3.5,
						spaceBetween: 10
					},
					'1280': {
						slidesPerView: 3.9,
						spaceBetween: 15
					},
					'1400': {
						slidesPerView: 5.5,
						spaceBetween: 20
					},
					'1600': {
						slidesPerView: 5.5,
						spaceBetween: 20
					},

				}}
				>
					{data?.map((d: ActionBlockData, i: number) => {
						return (
							<div
								style={{
									backgroundColor: `rgba(${hexToRGB(d?.color_code)}, 0.1)`
								}}
								key={i}
								className={styles.card}>
								<Link
									href={children[i]?.link || '/'}
									style={{
										textDecoration: 'none'
									}}>
									<div className={styles.card_top}>
										<div
											className={styles.card_title}
											style={{
												borderBottom: `2px solid ${d?.color_code}`
											}}>
											{d?.title}
										</div>
										<div className={styles.card_subtitle}>{d?.subtitle}</div>
									</div>
									<div className={styles.card_bottom}>
										<Image
											alt={d?.title || ''}
											src={getImage(d.file)}
											width={188}
											height={166}
											style={{
												objectFit: 'contain'
											}}
											priority
											unoptimized
										/>
									</div>
								</Link>
							</div>
						);
					})}
				</Slider>
			</div>
		</div>
	);
};

export default ActionSideScrollUnderline;
