import { ActionBlockData } from '@/services/types/blocksTypes';
import { getImage } from '@/services/utils/helper';
import { map } from 'lodash';
import Image from 'next/image';
import React from 'react';
import ActionVerticalButton from './Button';
import styles from './action-vertical.module.scss';
import Slider from '@/components/molecules/Slider';
import Link from 'next/link';
interface ActionsProps {
	data: ActionBlockData[];
	label: string;
	blockLinks?: any;
}
const ActionVertical: React.FC<ActionsProps> = ({ data, label, blockLinks }) => {
	const { children } = blockLinks;
	return (
		<div className='container'>
			<div className='text-center mb-30'>
				<h2 className='text-gray900 text-30 fw-600'>{label}</h2>
			</div>


			<Slider
				slidesPerView={6}
				spaceBetween={20}
				pagination={false}
				shouldSlidesPerGroup={true}
				responsive={{
					0: {
						slidesPerView: 2,
						spaceBetween: 20
					},

					768: {
						slidesPerView: 3,
						spaceBetween: 20
					},
					800: {
						slidesPerView: 2,
						spaceBetween: 20
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 20
					},
					1200: {
						slidesPerView: 4,
						spaceBetween: 20
					},
					1280: {
						slidesPerView: 4,
						spaceBetween: 20
					},
					1400: {
						slidesPerView: 6,
						spaceBetween: 20
					},
					1600: {
						slidesPerView: 6,
						spaceBetween: 20
					}
				}}

				className={'product-slider action_horizontal_container'}
			>
				{data?.map((d: ActionBlockData, i: number) => {
					return (
						<div
							key={i}
							className={`${styles.actions}`}
							style={{
								background: `linear-gradient(151deg, #FFFEF9 3.3%, ${d?.color_code} 93.34%)`
							}}>
							<div className={styles.icon_wrap}>
								<Image alt={d?.title} src={getImage(d.file)} width={65} height={65} priority unoptimized />
							</div>

							<div className={styles.content}>
								<div style={{ paddingLeft: 10 }}>
									<p>{d?.title}</p>
									{map(d.subtitle.split('|'), (item, index) => (
										<p
											key={index}
											style={{
												fontWeight: index === 0 ? 700 : 400
											}}
											className={index === 0 ? 'text-22' : 'text-18 '}>
											{item}
										</p>
									))}
								</div>

								<ActionVerticalButton
									button_title={d?.button_title}
									color_code={d?.color_code}
									link={children[i]?.link}
									target={children[i]?.target || '_self'}
								/>
							</div>
						</div>
					);
				})}
			</Slider>
			{/* <div className={styles.actions_wrap}>
				{data?.map((d: ActionBlockData, i: number) => {
					return (
						<div
							key={i}
							className={`${styles.actions}`}
							style={{
								background: `linear-gradient(151deg, #FFFEF9 3.3%, ${d?.color_code} 93.34%)`
							}}>
							<div className={styles.icon_wrap}>
								<Image alt={d?.title} src={getImage(d.file)} width={65} height={65} priority unoptimized />
							</div>
					
							<div className={styles.content}>
								<div style={{ paddingLeft: 10 }}>
									<p>{d?.title}</p>
									{map(d.subtitle.split('|'), (item, index) => (
										<p
											key={index}
											style={{
												fontWeight: index === 0 ? 700 : 400
											}}
											className={index === 0 ? 'text-22' : 'text-18 '}>
											{item}
										</p>
									))}
								</div>

								<ActionVerticalButton
									button_title={d?.button_title}
									color_code={d?.color_code}
									link={children[i]?.link}
									target={children[i]?.target || '_self'}
								/>
							</div>
						</div>
					);
				})}
			</div> */}
		</div>
	);
};

export default ActionVertical;
